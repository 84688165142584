* {
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  font-family: 'TTLakes-Regular';
  font-style: normal;
  font-weight: normal;
  margin: 0;
  padding: 0;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

button {
  font-family: 'TTLakes-Medium';
}

a {
  color: #0099ff;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

/* Header - Top Nav */
header {
  border-bottom: 2px solid #ffdb00;
}

.toggle-button {
  position: absolute;
  top: 0.75rem;
  right: 1rem;
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 21px;
}

.toggle-button .bar {
  height: 3px;
  width: 100%;
  background-color: #000;
  border-radius: 10px;
}

.navbar {
  font-family: 'TTLakes-Regular';
  font-style: normal;
  font-weight: normal;
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  color: #000;
  max-width: 1175px;
  margin: 0 auto;
}

.brand-title {
  font-size: 1.5rem;
  margin: 0.5rem 0px 0.5rem 0px;
}

.navbar-links {
  height: 100%;
}

.navbar-links ul {
  display: flex;
  margin: 0;
  padding: 0;
}

.navbar-links li {
  list-style: none;
  display: flex;
  align-items: center;
}

.navbar-links li a {
  display: flex;
  text-decoration: none;
  color: #000;
  padding: 1rem 0.75rem;
  align-items: center;
}

.navbar-links li a svg {
  margin-right: 5px;
}

.navbar-links li:hover {
  background-color: #ffdb00;
}

.dropbtn {
  cursor: pointer;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 260px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
}

.dropdown:hover .dropdown-content {
  display: block;
}

/* FOOTER */

.footer-wrapper {
  max-width: 1175px;
  margin: 0 auto;
}

footer {
  background-color: #66676b;
  padding: 20px 0;
  color: #fff;
  font-family: 'TTLakes-Regular';
  margin-top: auto;
  text-align: center;
}

.foot_nav a {
  color: #fff;
  padding: 0 0 40px 30px;
}

/* Start Content Area*/

.content-area {
  padding: 40px;
  flex: 1;
}

.center {
  padding: 40px;
  margin: 0 auto;
}

.breadcrumbs {
  max-width: 1175px;
  width: 80%;
  margin: 40px auto 0 auto;
  color: #c9c9c9;
}

.breadcrumbs a {
  font-size: 0.875rem;
  display: inline-block;
  padding: 0 7px;
  color: #488fcd;
}

.breadcrumbs span {
  font-size: 0.875rem;
  display: inline-block;
  padding: 0 7px;
}

.page-title {
  font-size: 2rem;
  padding-top: 40px;
  margin: 0 auto 20px auto;
}

.privacy-policy {
  max-width: 1175px;
  margin: 0 auto 60px auto;
  line-height: 1.7rem;
}

.sub-title {
  margin: 0 auto 20px auto;
}

ol.a {
  list-style-type: lower-alpha;
}

.text-bold {
  font-family: 'TTLakes-Medium';
}

.big-blue-btn,
.red-btn,
.green-btn,
.yellow-btn,
.disabled-btn,
.blue-btn,
.white-outline-btn {
  padding: 8px 20px;
  margin: 0 0 10px 10px;
  color: #fff;
  border: none;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.blue-btn,
.big-blue-btn {
  background-color: #488fcd;
}

.disabled-btn {
  background-color: #c9c9c9;
  color: #646569;
  cursor: default;
}

.red-btn {
  background-color: red;
}

.green-btn {
  background-color: #a1cc14;
  color: #000;
}

.yellow-btn {
  background-color: #ffdb00;
  color: #000;
}

.white-outline-btn {
  border: 2px solid #fff;
  background: none;
  padding: 10px 20px;
  font-size: 22px;
}

.big-blue-btn svg,
.red-btn svg,
.green-btn svg,
.yellow-btn svg,
.disabled-btn svg {
  margin-right: 10px;
}

.txtBtn {
  border: none;
  background-color: transparent;
  color: #0099ff;
  margin-right: 20px;
  cursor: pointer;
}

.txtBtn:hover {
  text-decoration: underline;
}

input[type='radio'] {
  margin-right: 10px;
  border: 1px solid #c9c9c9;
}

input[type='text'],
input[type='select'],
input[type='email'],
input[type='password'],
input[type='tel'] {
  border: 1px solid #c9c9c9;
  padding: 8px 10px;
  width: 100%;
  margin-bottom: 15px;
}

.web-container {
  max-width: 1175px;
  margin: 0 auto;
  display: flex;
}

/* -------------------------------Home Page Hero Section------------------------------- */
.web-hero {
  font-family: 'TTLakes-Regular';
  text-align: center;
  padding: 60px 0;
  background-repeat: no-repeat;
  background-size: 100%;
}

.web-hero-container {
  max-width: 1000px;
  margin: 0 auto;
}

.web-hero h1 {
  font-family: 'TTLakes-Medium';
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  color: #fff;
  width: 50%;
  margin: 0 auto;
  letter-spacing: 0.25rem;
}

.web-hero p {
  font-size: 18px;
  color: #fff;
  width: 55%;
  margin: 0 auto;
  line-height: 1.9rem;
  padding-top: 40px;
  font-style: normal;
  font-weight: normal;
}

/* Video Section */
.web-video {
  text-align: center;
  background-color: #efefef;
  padding: 0;
}

/* Outdated Roads Section */

.outdated-roads {
  display: flex;
  padding: 60px 0;
  justify-content: space-between;
  font-family: 'TTLakes-Regular';
}

.outdated-roads .or-right {
  padding-left: 60px;
}

.outdated-roads .or-right h2 {
  font-family: 'TTLakes-Medium';
  font-size: 28px;
  width: 50%;
  margin: 0;
}

/* Smart Pavement Section */
.smart-pavement {
  background-color: #ffdb00;
  display: flex;
  padding: 60px 0;
  font-family: 'TTLakes-Regular';
}

.smart-pavement .sp-right {
  padding: 50px 0 0 60px;
}

.smart-pavement .sp-right p {
  line-height: 1.8rem;
}

.smart-pavement .sp-left h2 {
  font-family: 'TTLakes-Medium';
  font-size: 28px;
}

.smart-pavement .sp-left p:first-of-type {
  font-family: 'TTLakes-Medium';
}

.smart-pavement .sp-left ul {
  list-style: none;
  padding-left: 0px;
  font-family: 'TTLakes-Regular';
  text-align: left;
}

.smart-pavement .sp-left ul li {
  padding-bottom: 10px;
  background: url('images/ir-bullet-white.png') no-repeat left center;
  padding: 5px 10px 5px 25px;
  list-style: none;
  margin: 0;
  vertical-align: middle;
}

.web-benefits {
  display: flex;
  padding: 60px 0;
  justify-content: space-between;
  font-family: 'TTLakes-Regular';
}

.web-benefits .wb-right {
  padding-left: 60px;
}

.web-benefits .wb-left h2 {
  font-size: 28px;
  width: 60%;
  margin: 0;
  font-family: 'TTLakes-Medium';
}

.in-action {
  padding: 60px 0;
  margin-bottom: 60px;
  justify-content: space-between;
  background-color: #efefef;
}

.in-action .ia-left {
  margin-right: 20px;
  width: 100%;
}

.in-action .ia-right {
  margin-left: 20px;
  width: 100%;
}

.in-action .ia-right .ia-header,
.in-action .ia-left .ia-header {
  width: 567px;
  height: 144.7px;
  background-repeat: no-repeat;
  background-size: auto;
  display: flex;
  flex-direction: column;
  color: #fff;
  align-content: flex-end;
}

.in-action .ia-left .ia-header {
  width: 100%;
}

.in-action .ia-right .ia-header {
  width: 100%;
}

.ia-header p {
  font-family: 'TTLakes-Regular';
  font-size: 22px;
  margin: 0;
  padding: 0 0 0 20px;
  margin-top: auto;
}

.ia-header h4 {
  font-size: 22px;
  margin: 0;
  padding: 0 0 10px 20px;
  font-family: 'TTLakes-Medium';
}

.ia-body {
  width: 90%;
  margin: 0 auto;
  font-family: 'TTLakes-Medium';
}

.web-cta {
  padding: 20px 0;
  background-color: #488fcd;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'TTLakes-Regular';
}

.web-cta p {
  font-size: 24px;
  color: #fff;
  padding: 0 50px 0 0;
  margin: 0;
}

.in-action ul,
.web-benefits ul,
.tech-box ul {
  list-style: none;
  padding-left: 0px;
  font-family: 'TTLakes-Regular';
}

.in-action ul li,
.web-benefits ul li,
.tech-box ul li {
  padding-bottom: 10px;
  background: url('images/ir-bullet.png') no-repeat left center;
  padding: 5px 10px 5px 25px;
  list-style: none;
  margin: 0;
  vertical-align: middle;
}

.web-contact {
  font-family: 'TTLakes-Regular';
  margin-bottom: 80px;
}

.web-contact p {
  padding-right: 20px;
  line-height: 1.7rem;
  width: 50%;
}

.web-contact form {
  padding-left: 20px;
  width: 50%;
}

.web-contact form input[type='text'],
input[type='email'],
select,
textarea {
  padding: 20px;
  border: 1px solid #c9c9c9;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
  resize: vertical;
  font-family: 'TTLakes-Regular';
}

.web-contact form select {
  width: 100%;
}

.web-contact form textarea {
  width: 100%;
}

input[type='submit'] {
  background-color: #488fcd;
  color: #fff;
  padding: 12px 20px;
  border: none;
  cursor: pointer;
}

/*Squeeze Page*/

.squeeze-box {
  padding: 0 40px;
  margin: 0 auto;
  text-align: center;
}

.squeeze-box .accent-line {
  margin: 20px auto 40px auto;
  height: 4px;
  width: 100px;
  background-color: #ffdb00;
}

.squeeze-box p {
  margin: 0 auto 40px auto;
  width: 50%;
}

.squeeze-box .squeeze-features {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
}

.squeeze-box .squeeze-features ul {
  margin: 0 40px;
  text-align: left;
  list-style-type: none;
}

.squeeze-box .squeeze-features ul li {
  padding: 5px 0;
}

.squeeze-box .squeeze-button {
  display: flex;
  justify-content: center;
}

.two-factor {
  margin-top: 40px;
}

.two-factor p {
  font-size: 0.875rem;
}

.tech-container,
.np-container {
  max-width: 1175px;
  margin: 0 auto;
}

.tech-flex,
.tech-flex-pull {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.tech-container img {
  width: 100%;
}

.tech-box {
  /*width: 45%;
  vertical-align: top;*/
  width: 560px;
  vertical-align: top;
  margin: 0 20px;
}

.tech-box h4 {
  font-family: 'TTLakes-Medium';
  padding: 0;
  margin: 0;
}

.tech-box img {
  width: 100%;
}

.tech-divider {
  width: 40%;
  height: 1px;
  margin: 60px auto;
  background-color: #ffdb00;
}

.tech-pad {
  margin-bottom: 40px;
}

.vid-outer {
  width: 100%;
  height: auto;
}

.vid-wrap {
  max-width: 1175px;
  padding-bottom: 60px;
  position: relative;
  margin: 0 auto;
}

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}

.video-container iframe,
.video-container object,
.video-container embed {
  border: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.tdaas-cta {
  margin: 60px 0 60px 0;
  padding: 20px 0 40px 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
}

.tdaas-cta h3 {
  font-family: 'TTLakes-Medium';
  font-size: 2rem;
  color: #fff;
  text-shadow: 2px 2px 4px #000;
}

.tdaas-cta .cta-btn {
  font-size: 1.5rem;
  padding: 20px 40px;
  border: none;
  color: #fff;
  background-color: #488fcd;
  cursor: pointer;
}

.np-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 60px;
}

.np-left {
  flex: 66.666666%;
  padding-right: 40px;
}

.np-right {
  flex: 33.333333%;
  border-left: 1px solid #c9c9c9;
  padding-left: 40px;
}

.np-stories {
  display: flex;
  padding-bottom: 40px;
  margin-bottom: 40px;
  border-bottom: 1px solid #c9c9c9;
}

.np-article h4 {
  font-family: 'TTLakes-Medium';
  margin: 0;
  padding: 0;
}

.np-img {
  margin-right: 20px;
}

.np-img img {
  width: 300px;
  height: auto;
}

.np-credentials {
  font-size: 0.875rem;
  color: #66676b;
}

@media (max-width: 768px) {
  .np-left,
  .np-right {
    flex: 100%;
    padding: 0 20px;
  }

  .np-right {
    border-left: none;
  }

  .np-stories {
    display: block;
  }

  .np-img {
    margin: 0 auto 20px auto;
  }

  .np-img img {
    width: 100%;
  }

  .navbar {
    flex-direction: column;
    align-items: flex-start;
  }

  .toggle-button {
    display: flex;
    top: 1.75rem;
  }

  .navbar-links {
    display: none;
    width: 100%;
    padding-right: 0px;
  }

  .navbar-links ul {
    width: 100%;
    flex-direction: column;
    background-color: #ffdb00;
  }

  .navbar-links ul li {
    text-align: center;
  }

  .navbar-links ul li a {
    padding: 0.5rem 1rem;
  }

  .navbar-links.active {
    display: flex;
  }

  .dropdown-content {
    display: block;
    position: relative;
    background-color: #ffdb00;
    box-shadow: none;
    margin-left: 10px;
    z-index: 0;
  }

  .web-container {
    display: inline-block;
    width: 90%;
  }

  .web-hero {
    padding: 40px 0;
    background-size: 155%;
    background-position: center;
  }

  .web-hero h1 {
    font-size: 26px;
    width: 90%;
    margin: 0 auto;
  }

  .web-hero p {
    font-size: 16px;
    width: 90%;
    line-height: 1.9rem;
    padding-top: 20px;
  }

  .outdated-roads {
    flex-direction: column;
    text-align: center;
    padding: 40px 0;
  }

  .outdated-roads .or-left img {
    width: 90%;
  }

  .outdated-roads .or-right {
    padding-left: 0px;
  }

  .outdated-roads .or-right h2 {
    font-size: 22px;
    width: 90%;
    margin: 20px auto;
  }

  .outdated-roads .or-right p {
    width: 90%;
    margin: 20px auto;
  }

  .smart-pavement {
    padding: 40px 0;
    flex-direction: column;
    text-align: center;
  }

  .smart-pavement .sp-left img {
    width: 100%;
  }

  .smart-pavement .sp-left {
    width: 90%;
    margin: 0 auto;
  }

  .smart-pavement .sp-right {
    width: 90%;
    padding: 20px 0 0 0;
    margin: 0 auto;
  }

  .web-benefits {
    padding: 40px 0;
  }

  .web-benefits .wb-right {
    width: 90%;
    padding: 20px 0 0 0;
    margin: 0 auto;
    text-align: center;
  }

  .web-benefits .wb-left {
    width: 90%;
    margin: 0 auto;
  }

  .web-benefits .wb-right img {
    width: 90%;
  }

  .web-benefits .wb-left h2 {
    text-align: center;
    width: 90%;
    margin: 0 auto 20px auto;
  }

  .in-action {
    padding: 40px 0;
    margin-bottom: 60px;
    text-align: center;
  }

  .in-action .ia-left {
    margin-bottom: 40px;
    text-align: left;
  }

  .in-action .ia-left .ia-header {
    margin: 0 auto;
  }

  .in-action .ia-right .ia-header {
    margin: 0 auto 20px auto;
  }

  .in-action .ia-right {
    margin-left: 0px;
    text-align: left;
  }

  .ia-header {
    margin: 0 auto;
  }

  .web-cta {
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .web-cta p {
    font-size: 24px;
    color: #fff;
    padding: 0 0 40px 0;
    margin: 0;
  }

  .web-contact p {
    margin: 0 auto;
    padding: 0 0 40px 0;
    width: 90%;
  }

  .web-contact form {
    margin: 0 auto;
    padding-left: 0px;
    width: 90%;
  }

  .privacy-policy {
    max-width: 90%;
  }

  ul {
    margin: 0 auto;
    width: 90%;
  }

  ul li {
    padding-bottom: 10px;
    background: url('images/ir-bullet.jpg') no-repeat left center;
    padding: 5px 10px 5px 25px;
    list-style: none;
    margin: 0;
    vertical-align: middle;
  }

  .footer-wrapper {
    max-width: 1175px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 20px;
  }

  footer {
    background-color: #66676b;
    padding: 20px 0;
    color: #fff;
    font-family: 'TTLakes-Regular';
  }

  .footer-wrapper p {
    order: 2;
  }

  .foot_nav {
    margin: 0 auto;
  }

  .foot_nav a {
    order: 1;
    padding-left: 10px;
  }

  .foot_nav a:first-child {
    padding-left: 0px;
  }

  .tech-container {
    margin: 20px;
  }

  .tech-flex {
    flex-direction: column;
  }

  .tech-flex-pull {
    flex-direction: column-reverse;
  }

  .tech-box {
    padding: 0px;
    width: 100%;
    margin: 0 auto;
  }

  .tech-divider {
    width: 60%;
    margin: 20px auto;
  }

  .breadcrumbs {
    width: 90%;
    margin: 40px auto 0 auto;
    font-size: 0.75rem;
  }

  .breadcrumbs a {
    font-size: 0.75rem;
  }

  .img-enlarge {
    display: none;
  }
}

@media only screen and (max-width: 926px) and (min-width: 769px) {
  .np-left,
  .np-right {
    padding: 0 20px;
  }

  .web-container {
    max-width: 90%;
  }

  .navbar {
    flex-direction: column;
    align-items: flex-start;
  }

  .dropdown-content {
    display: block;
    position: relative;
    background-color: #ffdb00;
    box-shadow: none;
    margin-left: 10px;
    z-index: 0;
  }

  .toggle-button {
    display: flex;
    top: 1.75rem;
  }

  .navbar-links {
    display: none;
    width: 100%;
    padding-right: 0px;
  }

  .navbar-links ul {
    width: 100%;
    flex-direction: column;
    background-color: #ffdb00;
  }

  .navbar-links ul li {
    text-align: center;
  }

  .navbar-links ul li a {
    padding: 0.5rem 1rem;
  }

  .navbar-links.active {
    display: flex;
  }

  .outdated-roads {
    flex-direction: column;
    text-align: center;
    padding: 40px 0;
  }

  .outdated-roads .or-left img {
    width: 90%;
  }

  .outdated-roads .or-right {
    padding-left: 0px;
  }

  .outdated-roads .or-right h2 {
    font-size: 22px;
    width: 90%;
    margin: 20px auto;
  }

  .outdated-roads .or-right p {
    width: 90%;
    margin: 20px auto;
  }

  .smart-pavement {
    padding: 40px 0;
    flex-direction: column;
    text-align: center;
  }

  .smart-pavement .sp-left img {
    width: 100%;
  }

  .smart-pavement .sp-left {
    width: 85%;
    padding: 0 20px;
    margin: 0 auto;
  }

  .smart-pavement .sp-right {
    width: 85%;
    padding: 20px;
    margin: 0 auto;
  }

  .web-benefits {
    padding: 40px 0;
  }

  .web-benefits .wb-right {
    width: 85%;
    padding: 20px 0 0 0;
    margin: 0 auto;
    text-align: center;
  }

  .web-benefits .wb-left {
    width: 85%;
    padding: 0 10px;
    margin: 0 auto;
  }

  .web-benefits .wb-right img {
    width: 90%;
  }

  .web-benefits .wb-left h2 {
    text-align: center;
    width: 90%;
    margin: 0 auto 20px auto;
  }

  .privacy-policy {
    max-width: 90%;
  }

  .tech-container {
    margin: 20px;
  }

  .tech-flex,
  .tech-flex-pull {
    flex-direction: column;
  }

  .tech-box {
    padding: 0px;
    width: 100%;
    margin: 0 auto;
  }

  .tech-divider {
    width: 60%;
    margin: 20px auto;
  }

  .img-enlarge {
    display: none;
  }
}

/* misc css below , not from mockup */
input[type='text'].input-error,
input[type='select'].input-error,
input[type='email'].input-error,
input[type='password'].input-error,
input[type='tel'].input-error,
textarea.input-error {
  border: 1px solid red !important;
  margin-bottom: 5px !important;
}

.error {
  color: red;
  font-size: 0.875rem;
  padding: 0;
  margin: 0 0 20px 0;
}

.navbar ul li {
  background: none;
}

.img-enlarge {
  font-size: 12px;
  text-align: center;
  padding-top: 10px;
}

.img-80 {
  width: 80%;
  margin: 0 auto;
}

.tech-anchors {
  text-align: center;
}

.tech-anchors h2 {
  margin: 0;
  padding: 0;
}

@media (min-width: 768px) {
  .ia-multiple {
    padding: 0px 0px 0px 20px;
    margin-bottom: 40px;
  }
}

.technology-tech-box {
  width: 560px;
  vertical-align: top;
}

.technology-tech-box img {
  width: 100%;
}

.stories-box {
  padding: 10px 10px 0px 10px;
  height: 175px;
}

@media (max-width: 768px) {
  .carousel-section {
    display: none;
  }

  .carousel-item {
    padding: 0px;
  }

  .stories-box {
    height: 400px;
    width: 250px;
  }

  .ia-right,
  .ia-multiple {
    margin-bottom: 40px;
  }

  .technology-tech-box {
    padding: 0px;
    width: 100%;
    margin: 0 auto;
  }
}

.dashboard-container {
  text-align: center;
  margin: auto;
  width: 90%;
}

@media only screen and (max-width: 926px) and (min-width: 769px) {
  .technology-tech-box {
    padding: 0px;
    width: 100%;
    margin: 0 auto;
  }
}

.smart-pavement-tech-container {
  margin-bottom: 50px;
}

.img-enlarge button {
  background: none !important;
  border: none;
  padding: 0 !important;
  font-family: arial, sans-serif;
  color: #069;
  text-decoration: none;
  cursor: pointer;
}

.react-multiple-carousel__arrow {
  background: rgba(92, 163, 226, 0.7) !important;
}

.stories-link {
  position: fixed;
  right: 20px;
}

.carousel-section {
  margin: '20px 40px 20px 20px';
  border-top: 'solid 2px gray';
  border-bottom: 'solid 2px gray';
}

.stories-title {
  margin: 0px;
}

.carousel-item {
  min-width: 600px;
}

.source-date-container {
  position: fixed;
  bottom: 10px;
}
