.org-tree * {
  padding: 0;
  margin: 0;
}

.org-tree {
  margin: auto;
  margin-bottom: 100px;
  margin-top: 50px;
}

.org-tree ul {
  padding-top: 20px;
  position: relative;

  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
}

.org-tree li {
  float: left;
  text-align: center;
  list-style-type: none;
  position: relative;
  padding: 20px 2px 0 2px;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
}

.org-tree li::before,
.org-tree li::after {
  content: '';
  position: absolute;
  top: 0;
  right: 50%;
  border-top: 1px solid #ccc;
  width: 50%;
  height: 20px;
}
.org-tree li::after {
  right: auto;
  left: 50%;
  border-left: 1px solid #ccc;
}

.org-tree li:only-child::after,
.org-tree li:only-child::before {
  display: none;
}

.org-tree li:only-child {
  padding-top: 0;
}

.org-tree li:first-child::before,
.org-tree li:last-child::after {
  border: 0 none;
}

.org-tree li:last-child::before {
  border-right: 1px solid #ccc;
}
.org-tree ul ul::before {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  border-left: 1px solid #ccc;
  width: 0;
  height: 20px;
}

.card .card-body {
  background: #fff;
  height: 120px;
  text-align: center;
  width: 200px;
  padding-top: 10px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  border: 1px solid #94a0b4;
}

.card .card-body h4,
.card .card-body div,
.card .card-body p,
.card .card-body span {
  padding-top: 5px;
}

.card .card-body p {
  line-height: 20px;
  font-size: 14px;
}

.org-chart-email,
.org-chart-bio {
  color: #5f9dd3;
  padding: 5px;
  font-size: 12px;
}

.org-chart-email:hover,
.org-chart-bio:hover {
  cursor: pointer;
  text-decoration: underline;
}

.colors {
  display: flex;
  justify-content: center;
}

.colors span {
  text-align: center;
  padding: 2px;
}

.org-divider {
  width: 90%;
  height: 1px;
  margin: 10px auto;
  background-color: #ffdb00;
}

@media (max-width: 768px) {
  .org-chart-bio {
    display: none;
  }

  .org-tree * {
    padding: 0;
    margin: 0 auto;
  }

  .org-tree .colors {
    margin: 15px;
  }

  .card .card-body {
    height: 130px;
    padding-top: 0px;
  }

  ul li {
    background: none;
  }

  .org-tree li {
    float: none;
  }

  .org-tree li:nth-child(1) {
    padding-top: 0px;
  }

  .org-tree ul ul::before,
  .org-tree li:last-child::before,
  .org-tree li::before,
  .org-tree li::after {
    border: none;
  }
}

.staff-image {
  height: 200px;
  width: auto;
}

.staff-container {
  display: flex;
}

.staff-info {
  margin-left: 20px;
}

.staff-info div {
  margin-bottom: 15px;
}

.close-button {
  border: none;
  display: inline-block;
  padding: 8px 16px;
  vertical-align: middle;
  overflow: hidden;
  text-decoration: none;
  color: inherit;
  background-color: inherit;
  text-align: center;
  cursor: pointer;
  white-space: nowrap;
}

.top-right {
  position: absolute;
  right: 5px;
  top: 5px;
  padding-left: 0px;
}

.teams ul {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  text-align: center;
}

.teams ul li {
  list-style: none;
  flex: 0 0 33.333333%;
  text-align: center;
  margin: 0 auto;
}

.teams ul li:nth-child(4),
.teams ul li:nth-child(5) {
  margin: 20px auto 0 auto !important;
}

.teams ul li span {
  display: inline-block;
  margin-right: 10px;
}
